<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Transaction" icon="right-left" />

    <div class="generalBorderlessBox">
        <div class="row mb-4">
            <div class="col-12">
                <div class="row">
                    <!-- <div class="col-12 text-danger small">sortField: {{sortField}}, sortOrder: {{sortOrder}}</div> -->
                    <div class="col-lg-1 mb-2" style="padding-top: 1px">
                        <div class="btn-group">
                            <button type="button" @click="$router.push({ path: '/backoffice/txn' })" class="btn btn-info text-white">
                                <fa icon="bars" />
                            </button>
                            <button type="button" @click="$router.push({ path: '/backoffice/txnchart' })" class="btn border text-grey">
                                <fa icon="chart-line" />
                            </button>
                        </div> 
                    </div>

                    <div class="col-lg-5 mb-2">
                        <table width="100%" border="0">
                            <tr>
                                <td>
                                    <VueDatePicker v-model="filterDateFr" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                </td>
                                <td class="text-primary fw-bold px-2" > - </td>
                                <td>
                                    <VueDatePicker v-model="filterDateTo" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div class="col-lg-3 mb-2 pt-1">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="home" /></span>
                            <!-- <input type="text" v-model="filterEntity" class="form-control" placeholder="Entity" @input="getEntityList" @blur="resetEntity"> -->
                            <input type="text" v-model="filterEntity" class="form-control" placeholder="Entity" @input="getEntityList">
                            <div class="list-group" id="listboxContainer" style="z-index: 50; position: absolute; top: 38px" >
                                <a v-for="e in arrEntityOpt" :key="e" href="#" class="list-group-item list-group-item-action" @click="selectEntity(e.entityId, e.entityName)">{{e.entityName}}</a>
                            </div>
                            <span v-if="filterEntity" class="input-group-text text-secondary bg-light isLink" @click="resetEntity"><fa icon="times" /></span>
                        </div>
                    </div>

                    <div class="col-lg-3 mb-2 pt-1">
                        <span class="filter-border border-0 p-0">
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="search" /></span>
                                <input type="text" v-model="filterName" class="form-control" placeholder="User / Email" @dblclick="filterName = ''">
                                <span v-if="filterName && filterName.length > 0" class="input-group-text" @click="filterName = ''" style="cursor: pointer">
                                    <fa icon="xmark" />
                                </span>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-6 mb-3 align-middle">
                            <table>
                                <tr>
                                    <td>Page {{ pgPage }} / {{pgLastPage}}</td>
                                    <td class="px-2"><fa icon="circle" style="font-size: 4px; padding-bottom: 3px; color: #dadcde" /></td>
                                    <td>{{pgRcdFr}} - {{pgRcdTo}} of {{total}} items</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <table class="text-center" align="right">
                                <tr>
                                    <td v-if="total > 0 && !pgArrPage.includes(1)" width="30px">
                                        <a class="isLink" href="#" @click="first"><fa icon="angles-left" /></a>
                                    </td>
                                    <td v-if="pgArrPage[0] > 1" width="35px">
                                        <a class="isLink" href="#" @click="previous"><fa icon="angle-left" /></a>
                                    </td>
                                    <td width="30px" v-for="p in pgArrPage" :key="p">
                                        <a @click="pgPage = p" class="isLink" :class="pgPage === p ? 'text-primary' : ''" href="javascript:void(0);">{{p}}</a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="30px">
                                        <a class="isLink" href="#" @click="next"><fa icon="angle-right" /></a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="35px">
                                        <a class="isLink" href="#" @click="last"><fa icon="angles-right" /></a>
                                    </td>
                                    <td class="align-middle"><div class="vr ms-1 mt-1"></div></td>
                                    <td>
                                        <a class="nav-link dropdown-toggle" href="#" id="sortLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <fa v-if="sortField === 'date' && sortOrder === 'asc'" icon="arrow-up-short-wide" class="text-primary" />
                                            <fa v-if="sortField === 'date' && sortOrder === 'desc'" icon="arrow-up-wide-short" class="text-primary" />
                                            <fa v-if="sortField === 'status' && sortOrder === 'asc'" icon="arrow-up-a-z" class="text-primary" />
                                            <fa v-if="sortField === 'status' && sortOrder === 'desc'" icon="arrow-up-z-a" class="text-primary" />
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="sortLink">
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'asc'">
                                                    <fa icon="arrow-up-short-wide" class="me-1" />Date ASC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'desc'">
                                                    <fa icon="arrow-up-wide-short" class="me-1" />Date DESC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'status' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'status'; sortOrder = 'asc'">
                                                    <fa icon="arrow-up-a-z" class="me-1" />Status ASC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'status' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'status'; sortOrder = 'desc'">
                                                    <fa icon="arrow-up-z-a" class="me-1" />Status DESC
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12">
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="(proj, pIdx) in arrDgProj" :key="proj.folderId">
                                        <td> 
                                            <router-link :to="'/p/' + proj.folderId">
                                                <div class="row" >
                                                    <div class="col-lg-3">
                                                        <div>{{ proj.jEntity.name }}</div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <table class="table table-borderless table-sm mb-0 pb-0" border="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="50px">
                                                                        <ImgAvatar :isUser="true" :id="proj.jUser.jAvatar.userId" displayName="" :isVerified="proj.jUser.isVerified" :isActive="proj.jUser.status" privilege="OWNER" align="right" width="30" height="30" />
                                                                        <!-- <Avatar :isUser="true" :avatar="proj.jUser.jAvatar.imgBase64" displayName="" privilege="OWNER" :isVerified="proj.jUser.isVerified" :isActive="proj.jUser.status" align="right" width="30" height="30" /> -->
                                                                    </td>
                                                                    <td>
                                                                        <div>{{proj.jUser.displayName}}</div>
                                                                        <div class="lblTiny text-grey">{{proj.jUser.userId}}</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div class="col-12 col-lg-3">
                                                        <Popper class="popperDark" arrow hover content="Document">
                                                            <span class="badge bg-grey isTooltipsLabel me-2"><fa icon="file" /> {{proj.totalDocument}}</span>
                                                        </Popper>
                                                        <Popper class="popperDark" arrow hover content="Signee">
                                                            <span class="badge bg-grey isTooltipsLabel"><fa icon="user" /> {{proj.totalSignee}}</span>
                                                        </Popper>

                                                        <ProjStatus :status="proj.status" class="ms-2" />
                                                        <span class="float-end">99</span>
                                                    </div>

                                                    <!-- Large screen -->
                                                    <div class="col-lg-2 text-end d-none d-md-block">
                                                        <table align="right" height="35px" border="0">
                                                            <tr>
                                                                <td class="align-middle small text-grey text-end">
                                                                    <div v-if="proj.dateModify !== 0">{{func.convDateTimeFormat(proj.dateModify, 'text')}}</div>
                                                                    <div v-else-if="proj.dateCreate !== 0">{{func.convDateTimeFormat(proj.dateCreate, 'text')}}</div>
                                                                    <div>
                                                                        <span v-if="proj.dateModify !== 0" class="isTooltipsLabel"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateModify), new Date()) }}</i></span>
                                                                        <span v-else-if="proj.dateCreate !== 0" class="isTooltipsLabel"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateCreate), new Date()) }}</i></span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <!-- Small screen -->
                                                    <div class="col-lg-2 d-md-none">
                                                        <div class="row small">
                                                            <div class="col-6">
                                                                <div v-if="proj.dateModify !== 0">{{func.convDateTimeFormat(proj.dateModify, 'text')}}</div>
                                                                <div v-else-if="proj.dateCreate !== 0">{{func.convDateTimeFormat(proj.dateCreate, 'text')}}</div>
                                                            </div>
                                                            <div class="col-6 text-grey text-end">
                                                                <span v-if="proj.dateModify !== 0" class="isTooltipsLabel"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateModify), new Date()) }}</i></span>
                                                                <span v-else-if="proj.dateCreate !== 0" class="isTooltipsLabel"><i>{{ func.getDateTimeDiff(func.convDateTimeFormat(proj.dateCreate), new Date()) }}</i></span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </router-link>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-6 mb-3 align-middle">
                            <table>
                                <tr>
                                    <td>Page {{ pgPage }} / {{pgLastPage}}</td>
                                    <td class="px-2"><fa icon="circle" style="font-size: 4px; padding-bottom: 3px; color: #dadcde" /></td>
                                    <td>{{pgRcdFr}} - {{pgRcdTo}} of {{total}} items</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-lg-6 mb-3">
                            <table class="text-center" align="right">
                                <tr>
                                    <td v-if="total > 0 && !pgArrPage.includes(1)" width="30px">
                                        <a class="isLink" href="#" @click="first"><fa icon="angles-left" /></a>
                                    </td>
                                    <td v-if="pgArrPage[0] > 1" width="35px">
                                        <a class="isLink" href="#" @click="previous"><fa icon="angle-left" /></a>
                                    </td>
                                    <td width="30px" v-for="p in pgArrPage" :key="p">
                                        <a @click="pgPage = p" class="isLink" :class="pgPage === p ? 'text-primary' : ''" href="javascript:void(0);">{{p}}</a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="30px">
                                        <a class="isLink" href="#" @click="next"><fa icon="angle-right" /></a>
                                    </td>
                                    <td v-if="total > 0 && !pgArrPage.includes(pgLastPage)" width="35px">
                                        <a class="isLink" href="#" @click="last"><fa icon="angles-right" /></a>
                                    </td>
                                    <td class="align-middle">
                                        <div class="vr ms-1 mt-1"></div>
                                    </td>
                                    <td>
                                        <a class="nav-link dropdown-toggle" href="#" id="sortLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <fa v-if="sortField === 'date' && sortOrder === 'asc'" icon="arrow-up-short-wide" class="text-primary" />
                                            <fa v-if="sortField === 'date' && sortOrder === 'desc'" icon="arrow-up-wide-short" class="text-primary" />
                                            <fa v-if="sortField === 'status' && sortOrder === 'asc'" icon="arrow-up-a-z" class="text-primary" />
                                            <fa v-if="sortField === 'status' && sortOrder === 'desc'" icon="arrow-up-z-a" class="text-primary" />
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="sortLink">
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'asc'">
                                                    <fa icon="arrow-up-short-wide" class="me-1" />Date ASC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'date' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'date'; sortOrder = 'desc'">
                                                    <fa icon="arrow-up-wide-short" class="me-1" />Date DESC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'status' && sortOrder === 'asc' ? 'active' : ''" href="#" @click="sortField = 'status'; sortOrder = 'asc'">
                                                    <fa icon="arrow-up-a-z" class="me-1" />Status ASC
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" :class="sortField === 'status' && sortOrder === 'desc' ? 'active' : ''" href="#" @click="sortField = 'status'; sortOrder = 'desc'">
                                                    <fa icon="arrow-up-z-a" class="me-1" />Status DESC
                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/stores/store'
import funcs from '@/functions/function'
import Popper from 'vue3-popper'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ProjStatus from '@/components/ProjStatus.vue'

export default {
    name: 'TxnList',
    components: { Alert, TopNavigation, Popper, VueDatePicker, /* Avatar, */ ImgAvatar, ProjStatus },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const totalFilter   = ref(0)
        const filterEntity  = ref('')
        const filterEntityId = ref('')
        const filterDateFr  = ref('')
        const filterDateTo  = ref('')
        const filterName    = ref('')
        const arrEntity     = ref([])
        const arrEntityOpt  = ref([])

        const total = ref(0)
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(5) // total record to display, ORIG: 15
        const arrDgProj = ref([])     // record shown in dg, total length shown as totalShown
        
        const pgRcdFr   = ref(1)    // Pagination rcd fr
        const pgRcdTo   = ref(1)    // Pagination rcd to
        const pgPage    = ref(1)    // Pagination page no selected
        const pgLastPage= ref(1)    // Pagination last page
        const pgNoPageList= ref(3)  // Pagination list n page number between previous and next
        const pgArrPage = ref([])   // Pagination pages listed

        const sortField = ref('date')
        const sortOrder = ref('desc')
        

        watch([filterEntity, filterName, filterDateFr, filterDateTo, sortField, sortOrder], () => {
            console.info('- - WATCH: Filter')
           
            if (filterEntity.value === '') {
                filterEntityId.value = ''
            }
            getProjList(true)
            arrEntityOpt.value = [] // for filter entity
        })
        
        watch([pgPage], () => {
            console.info('- - WATCH: pgPage', pgPage.value)

            if (pgPage.value === 1) {
                rcdFr.value = 0
            } else {
                rcdFr.value = (pgPage.value - 1) * rcdDisplay.value
            }
            
            pgRcdFr.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : (pgPage.value -1 ) * rcdDisplay.value + 1
            pgRcdTo.value = pgRcdFr.value + rcdDisplay.value - 1 >= total.value ? total.value : pgRcdFr.value + rcdDisplay.value - 1
            getProjList(false)
        })

        const getEntityList = () => {
            const p = {
                name: filterEntity.value,
                orderBy: [
                    {
                        field: 'name',
                        order: 'asc'
                    }
                ],
                "limit": "0,5"
            }

            axios.post('/report/su/entity/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    arrEntityOpt.value = res.data.data
                    // console.info('entity', JSON.stringify(res.data.data))
                }
            })
            .catch((error) => {
                // console.log('getEntityOpt | ERR', error)
            })
        }

        const selectEntity = (entityId, entityName) => {
            console.info('- - - click entity', entityId, entityName)
            filterEntity.value = entityName
            filterEntityId.value = entityId
            arrEntityOpt.value = []
        }

        const resetEntity = () => {
            arrEntityOpt.value = []
            filterEntity.value = ""
            filterEntityId.value = ""
        }

        const first = () => {

            let listBegin = 1
            let listLast = pgLastPage.value >= pgNoPageList.value ? pgNoPageList.value : pgLastPage.value

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 

            if (total.value === 0) pgRcdFr.value = 0
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const previous = () => {
            let listBegin = pgArrPage.value[0] - pgNoPageList.value <= 1 ? 1 : pgArrPage.value[0] - pgNoPageList.value
            let listLast = listBegin + (pgNoPageList.value - 1) >= pgLastPage.value ? pgLastPage.value : listBegin + (pgNoPageList.value - 1)

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const next = () => {
            let listBegin = pgArrPage.value[pgArrPage.value.length-1] + 1
            let listLast = listBegin + (pgNoPageList.value - 1) >= pgLastPage.value ? pgLastPage.value : listBegin + (pgNoPageList.value - 1)

            pgArrPage.value = []
            for (var i = listBegin; i <= listLast; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const last = () => {
            let listBegin = Math.ceil(pgLastPage.value / pgNoPageList.value) - 1 
            listBegin = listBegin * pgNoPageList.value + 1

            pgArrPage.value = []
            for (var i = listBegin; i <= pgLastPage.value; i++) {
                pgArrPage.value.push(i)
            } 
            pgRcdTo.value = rcdFr.value + rcdDisplay.value >= total.value ? total.value : rcdFr.value + rcdDisplay.value
        }

        const setDateFr = (val) => {
            filterDateFr.value = func.convDateTimeFormat(val, 'date')
        }

        const setDateTo = (val) => {
            filterDateTo.value = func.convDateTimeFormat(val, 'date')
        }

        const getProjList = async (setToFirst) => {

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value,
                orderBy: [
                    {
                        field: sortField.value,
                        order: sortOrder.value
                    }
                ] 
            }

            p.dateFrom = filterDateFr.value + ' 00:00:00'
            p.dateTo = filterDateTo.value + ' 23:59:59'

            if (filterName.value !== '' && filterName.value.length >= 3) {
                p.name = filterName.value
            }

            if (filterEntityId.value !== '') {
                p.entityId = filterEntityId.value
            }
          
            // console.info('before', arrDgProj.value.length)
            // console.info('getProjList REQ',JSON.stringify(p))

            axios.post('/report/su/signon/folder/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    total.value = res.data.description.rowCount
                    arrDgProj.value = res.data.data

                    // console.info('getProjList RES', 'rowCount', res.data.description.rowCount, 'data len', res.data.data.length)
                    // console.info('getProjList RES', JSON.stringify(res.data.data))

                    // show pagination
                    pgLastPage.value = Math.ceil(total.value / rcdDisplay.value)
                    if (setToFirst === true) {
                        pgPage.value = 1
                        first()
                    }
                }
            })
            .catch((error) => {
                console.log('getProjList | ERR', error)
               
            })
        }


        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {

            filterDateFr.value = func.convDateTimeFormat(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 8), 'date')
            filterDateTo.value = func.convDateTimeFormat(new Date(), 'date')

            console.info('filterDateFr', filterDateFr.value, filterDateTo.value)

            // getProjListIni()
        })

        return {
            alert, closeAlert, route, router, func, store, 
            totalFilter, filterEntity, filterEntityId, filterDateFr, filterDateTo, filterName, 
            arrEntity, setDateFr, setDateTo, arrEntityOpt, selectEntity, 
            total, rcdDisplay, arrDgProj, rcdFr, rcdDisplay, 
            pgRcdFr, pgRcdTo, pgPage, pgLastPage, pgArrPage, pgNoPageList, 
            first, previous, next, last, sortField, sortOrder,
            getEntityList, resetEntity
        }
    }
}
</script>

<style>
    .input-group-text {
        background-color: var(--bs-primary);
        color: var(--bgcolor-body-color);
    }

    .input-group-text2 {
        color: var(--accent-color);
    }

    .dropdown-item.active, .dropdown-item:active {
        /* background-color: var(--bs-primary); */
        background-color: #31d2f2;
        color: white;
    }
</style>